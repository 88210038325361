import React from 'react';
import { graphql } from 'gatsby';
import GameCreationRoot from '../../components/game-creation-root';
import BeCoinForm from '../../components/game-forms/be-coin-form';
import BeGameInstructions from '../../components/be-game-instructions';
import useLocalStorageState from 'use-local-storage-state'

const CoinGamePage = (props) => {
  const [experiment, setExperiment] = useLocalStorageState("experiment");
  const [dataUser, setDataUser] = React.useState();
  const handleOnChange = (event) => {
    dataUser[event.target.name] = event.target.value;
    setDataUser({...dataUser});
    console.log(dataUser);
  };

  React.useEffect(() => {
    setDataUser((props.location.state?.gamePosition !== undefined && experiment?.games[props.location.state.gamePosition].params) || {
        currency: '$',
        money: 0.1,
      }
    );
  }, []);

	return(
        <GameCreationRoot title={"Coin Game"}>
            <BeGameInstructions gameId={'coin'} dataUser={dataUser}  />
            <BeCoinForm handleOnChange={handleOnChange} />
        </GameCreationRoot>
	);
}

export default CoinGamePage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "side_bar", "games", "form_errors"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;